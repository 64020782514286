import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import InfiniteScroll from "react-infinite-scroll-component";
import { FiEdit } from "react-icons/fi"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { fetchPopularTask, fetchTasks } from "../../Actions/TasksAction";
import { fetchCategory } from "../../Actions/CategoryAction";
import ModalPopup from "./ModalPopup";
const Create = () => {
    const selector = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const [popular, setPopular] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [loader, setLoader] = useState(false);
    const [category, setCategory] = useState([]);
    const [tempID, setTempID] = useState(-1);
    const [show, setShow] = useState(false)
    const [searchItem, setSearchItem] = useState("");
    const [searchItem2, setSearchItem2] = useState("");
    const [UserInputs, setUserInputs] = useState([]);
    const [dataValue, setDataValue] = useState([]);
    const [id, setId] = useState("");
    const [search, setSearch] = useState({
        start: 1,
        end: 15
    })
    const showAllTask = () => {
        setLoader(true);
        dispatch(fetchPopularTask(setPopular));
        dispatch(fetchTasks(search, tasks, setTasks, setLoader));
    }
    const showAllCategory = () => {
        setLoader(true);
        dispatch(fetchCategory(setCategory, setLoader));
        // dispatch(fetchTasks(data2, tasks, setTasks, setLoader))
    }
    const searchData = (page = 1) => {
        setLoader(true);
        setSearch({
            ...search,
            page: page
        })

        console.log(search);
        let data2 = {
            start: page,
            end: 15

        }
        // let data = {
        //     page_number: page,
        //     keyword: search.keyword,
        // }
        dispatch(fetchTasks(search, tasks, setTasks, setLoader))
    }


    const ActiveClass = (id) => {
        setTempID(id);
    }
    const Popup = (index, id) => {
        setShow(true)
        setId(id);
        const data = tasks[index].promts;
        const regex = /\[(.*?)\]/g;
        const matches = data.match(regex);
        let values;
        if (matches) {
            values = matches.map(match => match.slice(1, -1));
            // const newval = values.map(item => item.replace(/ /g, '_'));
            setDataValue(values);
        }
        // let count = data.split('').filter(char => char === '[').length;
        // setInputField(count);

        const resut = values.reduce((objects, key) => {
            const obj = {}
            obj[key] = ""
            return [...objects, obj]
        }, []);

        // const resut = values.reduce((obj, key) => {
        //     obj[key] = "";
        //     return obj;
        //   }, {});
        setUserInputs(resut);
    }


    const hidePopup = () => {
        setShow(false)
    }


    useEffect(() => {
        showAllTask();
    }, []);
    useEffect(() => {
        showAllCategory()
    }, []);
    console.log("Taks", tasks);
    return (
        <>
            <TitleBar title="Projects" />
            <Navbar />

            <section className="siteWrap pb-5">

                <div className="project-head">
                    <div className="container">
                        <h2><img src={require('../../images/party-popper.png')} /> {selector.user.name}, So, what exactly do you have in mind?</h2>
                        <p>Begin with selecting the content type from the options below.</p>
                    </div>
                </div>

                <div className="project-filter">
                    <div className="container">
                        <div className="project-filter-main">
                            <ul>
                                <li><a className={tempID === -1 ? "active" : ''} style={{
                                    display: "inline-block",
                                    background: "#19122c",
                                    color: "#FFF",
                                    padding: "12px 30px",
                                    borderRadius: "50px",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    setTempID(-1)
                                }}>All</a></li>
                                {
                                    (category.length > 0) ?
                                        (category.map((list, index) => {
                                            return (
                                                <li key={index} onClick={() => { ActiveClass(list.id) }}>
                                                    <a style={{
                                                        display: "inline-block",
                                                        background: "#19122c",
                                                        color: "#FFF",
                                                        padding: "12px 30px",
                                                        borderRadius: "50px",
                                                        cursor: "pointer"
                                                    }}
                                                        className={list.id === tempID ? 'active' : ''}
                                                    >{list.categoryName}</a>
                                                </li>)
                                        })) : <></>
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="project-category">
                    <div className="container">
                        <div className="project-category-block">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2>Popular Task</h2>
                                <input
                                    type="text"
                                    className="add_form_control"
                                    placeholder="Search Here"
                                    onChange={(e) => { setSearchItem2(e.target.value) }}
                                    style={{ padding: "0 10px" }}
                                />
                            </div>
                            <div className="row">
                                {
                                    (popular.length > 0) ?
                                        (popular.filter((task) => {
                                            return task.description.toLowerCase().includes(searchItem2.toLowerCase())
                                        }).filter((task) => task.isPopular.includes(1)).map((task, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="col-lg-4">
                                                        <div className="project-category-single" style={{ cursor: "pointer" }}
                                                        onClick={() => Popup(index, task.id)}
                                                        >
                                                            <div className="project-category-icon"><FiEdit /></div>
                                                            <div className="project-category-txt">
                                                                <h3>{task.promptsName}</h3>
                                                                <p>{task.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })) :
                                        <div className="col-md-12 text-center">
                                            {loader ?
                                                <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#f721a0", fontSize: "30px" }} />
                                                : "No Data Found!"}
                                        </div>
                                }

                            </div>
                        </div>

                        <div className="project-category-block" >
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 >All Task</h2>
                                    <input
                                        type="text"
                                        className="add_form_control"
                                        placeholder="Search Here"
                                        onChange={(e) => { setSearchItem(e.target.value) }}
                                        style={{ padding: "0 10px" }}
                                    />
                                </div>
                            </div>
                            <div id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={tasks.length}
                                    next={() => searchData(search.page + 1)}
                                    hasMore={true}
                                    style={{ height: 500 }}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <div className="row" style={{ height: 500 }}>
                                        {
                                            (tasks.length > 0) ?
                                                (tasks.filter((task) => {
                                                    if (tempID === -1) {
                                                        return task
                                                    }
                                                    else {
                                                        return task.id.includes(tempID)
                                                    }
                                                }
                                                )).filter((curElem) => {
                                                    return curElem.description.toLowerCase().includes(searchItem.toLowerCase())
                                                }).map((task, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="col-lg-4" >
                                                                <div className="project-category-single alt"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => Popup(index, task.id)}>
                                                                    <div className="project-category-icon"><FiEdit /></div>
                                                                    <div className="project-category-txt">
                                                                        <h3>{task.promptsName}</h3>
                                                                        <p style={{ color: "#9e9e9e" }}>{task.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }) : <></>
                                        }
                                    </div>
                                </InfiniteScroll>
                                {
                                    loader ?
                                        <div className="">
                                            <h4 className='text-center mt-2'>
                                                <i className="fa fa-spinner fa-spin" style={{ color: "#f721a0" }} />
                                            </h4>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ModalPopup
                    show={show}
                    hidePopup={hidePopup}
                    dataValue={dataValue}
                    UserInputs={UserInputs}
                    setDataValue={setDataValue}
                    setUserInputs={setUserInputs}
                    id={id}
                />
            </section >

            <Footer />
        </>
    )
}

export default Create;