import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

const AiCoach = () => {

   return (
      <>
         <TitleBar title="Design" />
         <Navbar />
         <section className="siteWrap">
            <div className="siteWrap-in siteWrapN" style={{ paddingTop: "32px" }}>
               <div className="container-fluidn">

                  <div className="row justify-content-center">
                     <div className="col-xl-12">
                        <div className="business-top mb-0">
                           <h2>AI Coach</h2>
                        </div>
                     </div>
                  </div>

                  <div className="container">
                     <div className="convSec-search">
                        <div className="convSec-search-single">
                           <label for="">Team Member</label>
                           <select className="ml-3" name="" id="">
                              <option value="">Select team member</option>
                           </select>
                        </div>
                        <div className="convSec-search-single ml-5">
                           <label for="">Staff</label>
                           <select className="ml-3" name="" id="">
                              <option value="">Select Staff</option>
                           </select>
                        </div>
                     </div>
                  </div>
                  

                  <div className="container">
                  <div className="row">
                     <div className="col-sm-6 col-lg-4">
                        <div className="convSec-single">
                           <div className="convSec-img"><img src="https://images.pexels.com/photos/5384445/pexels-photo-5384445.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                           <div className="convSec-txt">
                              <h6 className="text-center">Add title here</h6>
                              <div className="staff-btn">
                                 <a className="demoLink mt-2">View</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6 col-lg-4">
                        <div className="convSec-single">
                           <div className="convSec-img"><img src="https://images.pexels.com/photos/5384445/pexels-photo-5384445.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                           <div className="convSec-txt">
                              <h6 className="text-center">Add title here</h6>
                              <div className="staff-btn">
                                 <a className="demoLink mt-2">View</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6 col-lg-4">
                        <div className="convSec-single">
                           <div className="convSec-img"><img src="https://images.pexels.com/photos/5384445/pexels-photo-5384445.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                           <div className="convSec-txt">
                              <h6 className="text-center">Add title here</h6>
                              <div className="staff-btn">
                                 <a className="demoLink mt-2">View</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6 col-lg-4">
                        <div className="convSec-single">
                           <div className="convSec-img"><img src="https://images.pexels.com/photos/5384445/pexels-photo-5384445.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                           <div className="convSec-txt">
                              <h6 className="text-center">Add title here</h6>
                              <div className="staff-btn">
                                 <a className="demoLink mt-2">View</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>

                  
                  

               </div>

            </div>


         </section >
         <Footer />

      </>
   )
}
export default AiCoach;