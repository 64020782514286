import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const fetchAccData = (setTeamData, setClientData) => (dispatch, getState) => {
    commonAxios("fetch-clients", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTeamData(res.data.team_members)
                setClientData(res.data.clients)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const addAccData = (userDetails, setUserDetails, fetchClientTeam, setLoader, setPasswordMsg, passwordMsg) => (dispatch, getState) => {
    commonAxios("create-client", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                fetchClientTeam()
                setUserDetails({
                    ...userDetails,
                    type: 'team',
                    email: '',
                    name: '',
                    password: ''
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false)
            setPasswordMsg({ ...passwordMsg, validation: false, msg: "" })
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })

}

export const onEditClient = (accountDetails, fetchClientTeam, setLoader, setToggle) => (dispatch, getState) => {
    commonAxios("edit-client-account", accountDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                dispatch(setAlert(res.msg, 'success'));
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
            setToggle(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}


export const onDeleteAcc = (data, fetchClientTeam, setLoader, Swal) => (dispatch, getState) => {
    commonAxios("delete-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                Swal.close()
                dispatch(setAlert(res.msg, 'success'))
            } else {
                dispatch(setAlert(res.msg, 'danger'))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, 'danger'))
            setLoader(false);
        })

}
