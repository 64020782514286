import React from 'react'
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineSearch, AiTwotoneEdit } from "react-icons/ai"
import { FiEdit } from "react-icons/fi"
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { deleteProjectWithId } from "../../Actions/TasksAction";
import SweetAlertTwo from "../SweetAlertTwo";
import Swal from "sweetalert2";
const ProjectRows = ({ data, search,fetchProjects }) => {

    const [button, setButton] = useState('Delete')
    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })
    const dispatch = useDispatch();
    
    const deleteProject = (id) => {
        setInfo({
            ...info,
            id:id,
            enable:true
        })
    }
    const onConfirm = (Swal) => {
        dispatch(deleteProjectWithId(info.id, fetchProjects,Swal))
        setInfo({
            ...info,
            id:-1,
            enable:false
        })

    }
    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }
    return (
        <tr>
            <td>
                <div className="proBox">
                    <div className="project-category-icon text-light"><FiEdit /></div>
                </div>
            </td>
            <td>
                <strong>
                    Hello
                </strong>
                <br />
                <span className="payMode">
                    {data.orginalContent}
                </span>

            </td>
            <td>

                <div className="d-flex Project_action">
                    {/* <span className="roundBox"style={{ position: 'relative', cursor: 'pointer' }}>
                     <GoLinkExternal size={20} className="align-icons" /></span> 
                     */}
                    <Link to={`/response-page?id=${data.id}`}
                        className="roundBox"
                        style={{ position: 'relative', cursor: 'pointer' }}
                        title="Edit"
                    >

                        <AiTwotoneEdit
                            size={20}
                            className="align-icons"
                        />
                    </Link>
                    <span
                        className="roundBox"
                        style={{ position: 'relative', cursor: 'pointer', color: 'red' }}
                        title="Delete"
                        onClick={() => { deleteProject(data.id) }}
                    >
                        <FiTrash2 size={20} className="align-icons" />
                    </span>


                </div>

            </td>
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text=" Do you want to remove this rebranding!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, remove it!'
                confirmTitle='Removing...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onConfirm}
                onCancel={handleCancle}
            />
        </tr>
    )
}

export default ProjectRows;