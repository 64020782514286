import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const fetchSocialAccounts = (token) => (dispatch, getState) => {
    let tokenVal = token ? token : getState().auth.token
    commonAxios("fetch-social-accounts", {}, dispatch, tokenVal)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_SOCIAL_ACCOUNTS", payload: res.data })
            }
        }).catch((error) => {
            console.log(error)

        })
}

export const saveFacebook = (data, fetchIntegration) => (dispatch, getState) => {
    const body = JSON.stringify(data);
    commonAxios("save-facebook", body, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                fetchIntegration()
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"));
            console.log(err);
        })
}

export const saveGoogle = (data, fetchIntegration) => (dispatch, getState) => {
    commonAxios("save-youtube", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                fetchIntegration()
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"));
            console.log(err);
        })
}


export const onDeleteSocials = (data, fetchIntegration, Swal) => (dispatch, getState) => {
    commonAxios("delete-social-network", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchIntegration()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            Swal.close()
            console.log(err)
        })
}


export const onConnectAutoRes = (data, checkStatus, setCheckStatus, randomString, setLoader) => (dispatch, getState) => {
    commonAxios("autoresponder-auth-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let myWindow = window.open(
                    res.data.outhUrl,
                    "_blank",
                    "width=700, height=500"
                );

                let interval = setInterval(() => {
                    if (checkStatus) {
                        dispatch(checkResponse(randomString, interval, myWindow, setCheckStatus)
                        );
                    }
                }, 5000);
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        })
        .catch((err) => {
            console.log(err);
            setLoader(false);
        });
}

export const checkResponse = (string1, interval, myWindow, setCheckStatus) => (dispatch, getState) => {
    let data = {
        key: string1,
    };
    commonAxios("check-network-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(fetchSocialAccounts())
                myWindow.close()
                setCheckStatus(false)
                dispatch(setAlert(res.msg, "success"));
                clearInterval(interval);
            } else {
                setCheckStatus(false)
            }
        })
        .catch((err) => {
            window.close();
            console.log(err)
        })
}


export const onConnectMailvio = (data, setLoader) => (dispatch, getState) => {
    commonAxios("autoresponder-auth-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                let obj = {
                    agencyId: data.agencyId,
                    clientId: data.clientId
                }
                dispatch(checkResponse(obj))

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err);
            setLoader(false)
        })
}