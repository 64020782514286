import React, { useState } from 'react'
import modalCloseIcon from "../../images/modal-close.png";
import { Modal } from 'react-bootstrap';
import { sendData } from "../../Actions/TasksAction"
import { useDispatch } from 'react-redux';
import {useNavigate} from "react-router-dom";
const ModalPopup = ({ show, hidePopup, dataValue, UserInputs, setDataValue, setUserInputs, id }) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const submitData = (e) => {
        e.preventDefault();
        setLoader(true)
        const api_Data = {
            id: id,
            InputVar: UserInputs,
            projectName:""
        }
        dispatch(sendData(api_Data, setLoader, navigate));
        //dispatch(sendData(setUserInputs,setLoader))
        hidePopup()

    }

    const onChangeHandel = (e, index) => {
        const name = e.target.name;
        const value = e.target.value;

        let data = [...UserInputs]
        data[index] = {
            ...data[index],
            [name]: value
        }

        setUserInputs(data);

    }
    return (
        <>
            <Modal className="VideoModal dark" show={show} onHide={hidePopup} centered>
                <Modal.Body>
                    <div onClick={hidePopup} className="vidClose">
                        <img alt="" src={modalCloseIcon} />
                    </div>
                    <div className="modal-txt">
                        <div className="modal-txt-top">
                            <h4>Create Project</h4>
                        </div>

                    </div>
                    <div style={{ padding: "30px 0" }}>
                        <form onSubmit={submitData}>
                            <div className="row align-items-center">

                                {
                                    (UserInputs.length > 0) ?
                                        (
                                            UserInputs.map((data, index) => {
                                                return (
                                                    <React.Fragment>
                                                    <div className="col-md-6">
                                                        <div class="form-group">
                                                                <label for="exampleFormControlInput1" style={{textTransform: 'capitalize'}} >{(dataValue[index].replace(/X/g,"Number Of"))} :</label>
                                                                   
                                                                        <input type="text" 
                                                                         className="form-control add_form_control"
                                                                         id="exampleFormControlInput1"
                                                                         name={dataValue[index]}
                                                                         onChange={(e) => { onChangeHandel(e, index) }}
                                                                         placeholder="Enter your input" 
                                                                         required />
                                                            </div>
                                                    </div>
                                                    </React.Fragment>
                                                );
                                            })
                                        ) : ''
                                }
                            </div>
                            <div className="text-end">
                                <button type="submit" className="btn btn-primary">Submit
                                    {loader ?
                                        <i className="fa fa-spinner fa-spin mx-1" />
                                        : ""}
                                </button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
            </Modal >

        </>
    )
}

export default ModalPopup
