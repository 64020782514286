import React from "react";
import img1 from "../../images/IMAC.png"
import img2 from "../../images/IMAC.png"
import img3 from "../../images/IMAC.png"
import img4 from "../../images/IMAC.png"
import img5 from "../../images/IMAC.png"
import img6 from "../../images/IMAC.png"
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";


const DashboardAbout = () => {
    const rebrandData = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="featureSec">
                <div className="container-fluid">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2>Supercharge Your  <span className="add_color">Productivity</span> </h2>
                                        <ul className="dash-list">
                                            <li>{rebrandData ? rebrandData.name : appName} ignites your productivity, allowing you to accomplish in minutes what used to take hours.
                                            </li>
                                            <li>With an extensive selection of marketing, sales, operations, productivity, and customer support prompts, you'll leave behind the chains of manual labor and soar towards success.
                                            </li>
                                            <li>Utilize our intuitive prompts to streamline your marketing efforts, reclaiming those precious hours and redirecting them towards strategic growth initiatives that truly propel your business forward.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img5} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2>Surpass Average <span className="add_color"> Expectations
                                        </span> </h2>
                                        <ul className="dash-list">
                                            <li>Don't settle for being an average business owner spending 100 hours a month on marketing.
                                            </li>
                                            <li>With  {rebrandData ? rebrandData.name : appName}, you can break free from the time-consuming tasks that hold you back.
                                            </li>
                                            <li>Offers an opportunity for businesses to explore new areas of growth and expansion with the help of AI expertise.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img6} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>ULTIMATE SOLUTION TO DRIVE PERPETUAL LEADS</h6> */}
                                        <h2> <span className="add_color">Effortless</span> Prompting</h2>

                                        <ul className="dash-list">
                                            <li>Crafting the perfect prompt can be a brain-breaking exercise, but not with DoesItAll.
                                            </li>
                                            <li>Our web app simplifies the process with our one-click templates, removing the guesswork and saving you from the frustration of starting from scratch.
                                            </li>
                                            <li>Access our expertly designed prompts that cover various business areas, and effortlessly customize them to fit your unique needs.</li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img1} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>AUTOMATIC AD CREATION</h6> */}
                                        <h2>Reclaim Your <span className="add_color">Time</span> </h2>
                                        <ul className="dash-list">
                                            <li>Time is a precious commodity, and  {rebrandData ? rebrandData.name : appName} understands that. Say goodbye to the days of spending endless hours on sales plans and outreach emails.
                                            </li>
                                            <li>Our prompts have been meticulously crafted to streamline these tasks, transforming what used to take days into mere minutes.
                                            </li>
                                            <li>Reclaim those missed family occasions and create lasting memories while achieving your business goals.
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img2} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2>Simplified User <span className="add_color">Experience
                                        </span> </h2>
                                        <ul className="dash-list">
                                            <li>No coding skills? No problem!   {rebrandData ? rebrandData.name : appName} eliminates the need for a background in engineering or technical expertise.
                                            </li>
                                            <li>We've done the heavy lifting for you through extensive experimentation and optimization.</li>
                                            <li>Now, all you need to do is browse our prompt library, pick the one that aligns with your objective, and click your way to success.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img3} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2> <span className="add_color">Streamline </span> Your Business
                                        </h2>
                                        <ul className="dash-list">
                                            <li>  {rebrandData ? rebrandData.name : appName} empowers you to streamline your entire business operations. From marketing and sales to operations and customer support, our comprehensive prompt library covers a wide range of business areas.
                                            </li>
                                            <li>By leveraging our prompts, you can eliminate bottlenecks, enhance productivity, and focus on the core aspects that drive your business growth.
                                            </li>
                                            <li>Maximize efficiency and achieve more in less time with   {rebrandData ? rebrandData.name : appName}.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img4} /></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
        </>
    )
}

export default DashboardAbout;