import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const fetchPopularTask = (setPopular) => (dispatch,getState)=>{
    commonAxios("fetch-popular-task",{},dispatch,getState().auth.token)
    .then((res)=>{  
        if (res.status) {
            setPopular(res.data)
        }else{
            dispatch(setAlert(res.msg,"danger"))
        }
    }).catch((err)=>{
        console.log(err);
    })
}

export const fetchTasks = (data,tasks,setTasks, setLoader) => (dispatch, getState) => {//here we are fetching task 
    commonAxios("fetch-task", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...tasks]
                setTasks(arr.concat(res.data))
            } else {
                setTasks([])
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const sendData = (api_Data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("generate-project", api_Data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/response-page?id=${res.data}`);
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).cath((err) => {
            setLoader(false);
            console.log(err);
        })
}

export const sendId = (id, setData) => (dispatch, getState) => {
    commonAxios("fetch-project", id, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data[0]);
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err);
        })
}

export const regenerateAIData = (data, setData, setLoader) => (dispatch, getState) => {
    const allData = {
        id: data.id,
        InputVar: data.data
    }

    commonAxios("generate-script", allData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData({ ...data, orginalContent: res.data })
                setLoader({
                    ...data,
                    two: false
                })
                console.log(res);
            } else {
                setLoader({
                    ...data,
                    two: false
                })
                dispatch((setAlert(res.msg, "danger")));
            }
        }).catch((err) => {
            setLoader({
                ...data,
                two: false
            })
            console.log(err);
        })
}

export const sendAIData = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("save-project", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate("/projects");
                dispatch(setAlert(res.msg, "success"));
                setLoader({
                    ...data,
                    one: false
                })
            }
            else {
                dispatch((setAlert(res.msg, "danger")))
            }
            setLoader({
                ...data,
                one: false
            })
        }).catch((err) => {
            setLoader({
                ...data,
                one: false
            })
            console.log(err);
        })
}

export const allProjects = (setData,setLoader)=>(dispatch,getState)=>{
    commonAxios("fetch-all-project",{},dispatch,getState().auth.token)
    .then((res)=>{  
        if(res.status){
            setData(res.data)
        }else{
            dispatch(setAlert(res.msg,"danger"));
        }
        setLoader(false);
    }).catch((err)=>{
        console.log(err);
    })
}

export const deleteProjectWithId=(id,fetchProjects,Swal)=>(dispatch,getState)=>{
    const projectId={
        id:id
    }
    commonAxios("delete-project",projectId,dispatch,getState().auth.token)
    .then((res)=>{
        if (res.status) {
            dispatch(setAlert(res.msg,"success"))
            fetchProjects();
        }else{
            dispatch(setAlert(res.msg,"danger"))
        }
        Swal.close()
    }).catch((err)=>{
        dispatch(setAlert(err.msg,"danger"))
        console.log(err);
    })
}