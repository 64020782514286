import React, { useState } from 'react'
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import Footer from "../Footer";
// import dummyProduct from '../../images/dummyProduct.jpg'
import { BsPlusLg } from 'react-icons/bs';
import { GoLinkExternal } from "react-icons/go";
import dots from '../../images/dots.png';
import { BsThreeDots } from 'react-icons/bs';

import { ImEmbed2 } from "react-icons/im";
import { Dropdown } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { GrShareOption } from "react-icons/gr";
import { VscCopy } from "react-icons/vsc";
import { AiOutlineSearch, AiTwotoneEdit } from "react-icons/ai"
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { allProjects } from "../../Actions/TasksAction"
import ProjectRows from './ProjectRows';
import { Link } from "react-router-dom";
const Projects = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);

    const fetchProjects = () => {
        dispatch(allProjects(setData, setLoader))
    }

    useEffect(() => {
        fetchProjects()
    }, []);
    
    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    return (
        <>
            <TitleBar title="Dashboard" />
            <Navbar />

            <section className="siteWrap">
                <div className="Project_Wrap">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="Project_tittle">
                                    <h2>Your Projects</h2>
                                    <Link to="/create">
                                        <button className="demoLink mt-0"><BsPlusLg size={20} style={{ marginRight: "5px", marginBottom: "3px" }} /> Create Project</button>
                                    </Link>
                                </div>
                                <hr style={{ opacity: "1", color: "#f721a0", margin: " 10px 120px" }} />
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="Project_Table" style={{ marginTop: "40px" }}>
                                        <div className="Project_Sort">
                                            <div className="Project_Sort_single">
                                                <div className="proSearch">
                                                    <span className='searchIcon'><AiOutlineSearch /></span>
                                                    <input
                                                        className='inpSearch withIcon'
                                                        type="text"
                                                        placeholder="Search a product by name"
                                                        onChange={(e) => { handleSearch(e) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="productSort-single ms-2">
                                                <select className='inpSearch' >
                                                    <option value={0}>All</option>
                                                    <option value={1}>Sandbox</option>
                                                    <option value={2}>Live</option>
                                                </select>
                                            </div>

                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped themeTable">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th className="cursor-pointer" >Name</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {
                                                        (data.length > 0) ?
                                                            (
                                                                data.filter((project) => project.orginalContent.toLowerCase().includes(search.toLowerCase()))
                                                                    .map((project, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <ProjectRows
                                                                                    data={project}
                                                                                    fetchProjects={fetchProjects}
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    })

                                                            ) : ''
                                                    }
<tr className="loderTabel">
    <td colSpan={3} style={{border:"none",boxShadow:"none"}}>
                                                    {loader ?
                                                        <i className="fa fa-spinner fa-spin mr-2 " style={{ color: "#f721a0", fontSize: "30px" }} />
                                                        : ""}
                                                        </td>
                                                        </tr>

                                                </tbody>

                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >


            <Footer />







        </>
    )
}

export default Projects
