import './App.css';
import './css/design-fonts.css'
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from './Components/Auth/Login';
import ForgetPassword from './Components/Auth/ForgetPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import MasterLogin from './Components/Auth/MasterLogin';
import { PrivateRoute } from './Components/PrivateRoute';
import { HelmetProvider } from 'react-helmet-async';
import Dashboard from './Components/Dashboard/Dashboard';
import Profile from './Components/User/Profile';
import Privacy from './Components/User/Privacy';
import AccountManagement from './Components/User/AccountManagement';
import Upgrades from './Components/User/Upgrades';
import HelpSupport from './Components/Support/HelpSupport';
import Training from './Components/Support/Training';
import TrainingArticles from './Components/Support/TrainingArticles';
// import Integration from './Components/Integration/Integration';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './Actions/AuthAction';
import { removeAlert } from './Actions/AlertAction';
import Alert from './Components/Alert';
import AiCoach from './Components/AiCoach/AiCoach';
import Projects from './Components/Projects/Projects';
import Create from './Components/Create/Create';
import ResponsePage from './Components/ResponsePage';
function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  useEffect(() => {
    dispatch(loadUser())
  }, [])

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  return (
    <div className="App">
      <Alert />
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/forget-password' element={<ForgetPassword />} />
            <Route exact path='/reset-password' element={<ResetPassword />} />
            <Route exact path='/master-login' element={<MasterLogin />} />

            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/create" element={<PrivateRoute><Create /></PrivateRoute>} />
            <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /></PrivateRoute>} />
            <Route exact path="/help-and-support" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/response-page" element={<PrivateRoute><ResponsePage /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><TrainingArticles /></PrivateRoute>} />
            {/* <Route exact path="/integration" element={<PrivateRoute><Integration /></PrivateRoute>} /> */}


            {/* <Route exact path="/agency" element={<PrivateRoute><Agency /></PrivateRoute>} >
              <Route index element={<AgencyList />} />
              <Route path="create-agency" element={<PrivateRoute><CreateAgency /></PrivateRoute>} />
            </Route> */}
            {/* <Route exact path="/clients" element={<PrivateRoute><Clients /></PrivateRoute>} /> */}



            <Route exact path="/ai-coach" element={<PrivateRoute><AiCoach /></PrivateRoute>} />


            <Route exact path="/projects" element={<PrivateRoute><Projects /></PrivateRoute>} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
