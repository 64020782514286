import produce from "immer"
const initialState = {
    data: {
        facebook: [],
        youtube: [],
        // getResponse: [],
        // mailchimp: [],
        // aweber: [],
        // mailvio: []
    }
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_SOCIAL_ACCOUNTS":
            return produce(state, (draft) => {
                draft.data.facebook = action.payload.facebook ? action.payload.facebook : []
                draft.data.youtube = action.payload.youtube ? action.payload.youtube : []
                // draft.data.getResponse = action.payload.getresponse ? action.payload.getresponse : []
                // draft.data.mailchimp = action.payload.mailchimp ? action.payload.mailchimp : []
                // draft.data.aweber = action.payload.aweber ? action.payload.aweber : []
                // draft.data.mailvio = action.payload.Mailvio ? action.payload.Mailvio : []
            })
        default:
            return state
    }
}