import React from 'react'
import TitleBar from "../Components/TitleBar";
import Navbar from "../Components/Navbar";
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import { sendId, sendAIData, regenerateAIData } from "../Actions/TasksAction"
import { useDispatch } from "react-redux";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
const ResponsePage = () => {
    const [data, setData] = useState({
        data: [],
        orginalContent: ""
    })
    const [loader, setLoader] = useState({
        one:false,
        two:false,
    });
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let id = location.search.split('=')[1];
    let id_Data = {
        id: id
    }
    const SendAPI_ID = () => {
        dispatch(sendId(id_Data, setData));
    }
    useEffect(() => {
        SendAPI_ID()
    }, []);
console.log(data);
    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }
    const handleChangeInput = (e, index) => {
        let { name, value } = e.target
        let inputs = [...data.data]

        inputs[index] = {
            ...inputs[index],
            [name]: value
        }
        setData({
            ...data,
            data: inputs
        });
    }

    const regenerateTask = () => {
        setLoader({
            ...data,
            two:true
        })
        dispatch(regenerateAIData(data,setData,setLoader))
    }

    const submitData = (e) => {
        e.preventDefault();
        setLoader({
            ...data,
            one:true
        })
        dispatch(sendAIData(data, setLoader, navigate))
    }
    return (
        <>
            <TitleBar title="Dashboard" />
            <Navbar />

            <section className="siteWrap">
                <div className="Project_Wrap">
                    <div className="container-fulid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="Response_page_tittle" >
                                    <i class="fa-sharp fa-solid fa-arrow-left" style={{ color: "#fff" }}
                                    onClick={()=>{navigate(-1)}}
                                    ></i>
                                    <h2>Task</h2>
                                </div>
                                <hr style={{ opacity: "1", color: "#f721a0", margin: " 10px 120px" }} />
                            </div>
                        </div>

                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="Response_main_wrap">
                                    <form onSubmit={submitData}>
                                        <div className="row align-items-center">
                                                {
                                                    (data.data.length > 0) ?
                                                    
                                                        (
                                                            data.data.map((task, index) => {
                                                                return (
                                                                    <React.Fragment>
                                                                       
                                                                        <div className="col-md-6">
                                                                  <div class="form-group">
                                                                 <label for="exampleFormControlInput1" class="form-label ">{Object.keys(task)} :</label>
                                                                   
                                                                                  <input
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    id="exampleFormControlInput1"
                                                                                    placeholder="name@example.com"
                                                                                    value={task[Object.keys(task)]}
                                                                                    name={Object.keys(task)}
                                                                                    onChange={(e) => handleChangeInput(e, index)}
                                                                                />
                                                            </div>
                                                    </div>
                                                                    </React.Fragment>
                                                                )
                                                            })

                                                        ) : ''
                                                }

                                        </div>
                                        <div className="response_heading">
                                            <h4> Response</h4>
                                            <hr style={{ opacity: "1", color: "#f721a0", margin: " 10px 0" }} />
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <div class="form-floating">
                                                        <textarea
                                                            class="form-control response_textarea"
                                                            style={{ paddingTop: "30px" }}
                                                            placeholder="Leave a comment here"
                                                            id="floatingTextarea"
                                                            value={data.orginalContent}
                                                            name="orginalContent"
                                                            onChange={(e) => handleChange(e)}
                                                        ></textarea>
                                                        <label for="floatingTextarea" > Task Response</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: "30px", textAlign: "end", with: "100%" }}>
                                            <button class="btn btn-primary me-2" type='submit'>
                                                Save
                                                {loader.one ?
                                                    <i className="fa fa-spinner fa-spin mx-1" />
                                                    : ""}
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                onClick={regenerateTask}
                                            >Regenerate
                                                {loader.two ?
                                                    <i className="fa fa-spinner fa-spin mx-1" />
                                                    : ""}
                                            </button>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >


        </>
    )
}

export default ResponsePage
