import { commonAxios } from "../Global/CommonAxios"

export const fetchCategory = (setCategory,setLoader)=>(dispatch,getState)=>{
    commonAxios("fetch-category",{},dispatch,getState().auth.token)
    .then((res)=>{
        if(res.status){
            setCategory(res.data);
        }else{
            setCategory([]);
        }
        setLoader(false);
    }).catch((err)=>{
        setLoader(false);
        console.log(err.msg);
    })
}