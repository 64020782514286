import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import iconWrite from "../../images/icon-write.svg";
import iconRight from "../../images/icon-arrow.svg";
import modalCloseIcon from "../../images/modal-close.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { appName } from "../../Global/Global";
import { BiConversation } from "react-icons/bi";
import ProgressBar from 'react-bootstrap/ProgressBar';
// import { onFetchProgress, onFetchStaffDataForDashboard } from "../../Actions/StaffAction";

import video from '../../video/video.mp4'

const DashboardHead = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const rebrandData = useSelector((state) => state.rebrand.data);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [progress, setProgress] = useState(0)
  const [state, setState] = useState({
    hiredStaff: 0,
    totalStaff: 0,
  })

  return (
    <>
      <section className="hero-banner">
        <div className="hero-video">
          <video autoPlay loop muted style={{ width: "100%" }} src={video} />
        </div>
        <div className="hero-banner-txt">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="hero-banner-left">
                  <h2>WELCOME TO</h2>
                  <h3 className="text-uppercase">
                    {rebrandData ? rebrandData.name : appName}
                  </h3>
                  <p>
                    Revolutionize your business effortlessly:  {rebrandData ? rebrandData.name : appName}'s  intelligent, cost-saving AI workforce delivers expertise on demand and drives unprecedented success!
                  </p>
                  <button onClick={handleShow} className="demoLink">
                    <i className="fa fa-play" aria-hidden="true"></i> Watch Demo
                    Video
                  </button>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero-banner-right">
                  {auth.user.isClient === "0" ? (
                    <>
                      <Link to="/create" className="bannerLink">
                        <div className="bannerLink-left">
                          {/* <span>
                            <div className="numberCount">{state.hiredStaff}/{state.totalStaff}</div>
                          </span> */}
                          Create
                        </div>
                        <div className="bannerLink-right">
                          <img alt="" src={iconRight} />
                        </div>
                      </Link>
                      {/*
                      <Link to="" className="bannerLink">
                        <div className="bannerLink-left">
                           <span>
                            <div className="numberCount"><BiConversation /></div>
                          </span> 
                          Your Conversations
                        </div>
                        <div className="bannerLink-right">
                          <img alt="" src={iconRight} />
                        </div>
                      </Link>
                        */}

                      {
                        /*<Link to="" className="bannerLink">
                          <div className="bannerLink-left">
                            Add Text Here
                             <span>
                              <div className="numberCount">{progress}%</div>
                            </span> 
                          </div>
                          <div className="bannerLink-right">
                            <img alt="" src={iconRight} />
                          </div>
                           <div style={{ width: "100%" }}>
                            <ProgressBar variant="danger" animated now={progress <2 ? progress + 2 : progress } />
                          </div> 
                        </Link>*/
                      }
                    </>
                  ) : (
                    <Link
                      to={`/agency/create-client?id=${auth.user.agencyId}&cId=${auth.user.id}`}
                      className="bannerLink"
                    >
                      <div className="bannerLink-left">
                        <span>
                          <img alt="" src={iconWrite} />
                        </span>
                        View Details
                      </div>
                      <div className="bannerLink-right">
                        <img alt="" src={iconRight} />
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal className="VideoModal" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div onClick={handleClose} className="vidClose">
            <img alt="" src={modalCloseIcon} />
          </div>
          <div className="modalVidWrap">
            <div className="modalVid">
              <iframe
                src="https://player.vimeo.com/video/808640412?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false;"
                allowFullScreen="true"
                allow="autoplay,fullscreen,picture-in-picture"
                title="Player for FastPaid Intro"
                data-ready="true"
                tabIndex="-1"
              ></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardHead;
